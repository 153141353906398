@keyframes load1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(1.6);
  }
  80% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes load2 {
  0% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(1.4);
  }
  40% {
    transform: scale(1.6);
  }
  60% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes load3 {
  0% {
    transform: scale(1.4);
  }
  20% {
    transform: scale(1.6);
  }
  40% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.loader {
  background-color: $background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin: 7px;
    &.dot-1 {
      background-color: $primary-color;
      animation: load1 1s infinite;
    }
    &.dot-2 {
      background-color: $third-color;
      animation: load2 1s infinite;
    }
    &.dot-3 {
      background-color: $clic-color;
      animation: load3 1s infinite;
    }
  }
}
