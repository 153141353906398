@use "variables" as *;

.home {
  background-color: $background-color;
  padding-top: 105px;
  padding: 50px;
  @media (max-width: 992px) {
    padding: 30px;
  }
  @media (max-width: 576px) {
    padding: 15px;
  }
  .background-image {
    height: 100%;
    width: 100%;
    background-image: url("../../public/pictures/cabinet-16.jpeg");
    background-position: center;
    background-size: cover;
    position: relative;
    &::after {
      content: "";
      opacity: 0.6;
      background-color: $background-color;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
    }

    > div {
      opacity: 0;
      transition: all 1s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        height: 100px;
        margin-top: 20px;
        transition: margin-top 0.5s;
      }
      nav {
        padding-top: 30px;
        margin-top: 5px;
        transition: margin-top 0.5s;
        transition-delay: 0.8s;
        ul {
          display: flex;
          list-style-type: none;
          padding: 0;
          li {
            a {
              text-decoration: none;
              color: $primary-color;
              font-size: 20px;
              padding: 7px 10px;
              position: relative;
              &:after {
                content: "";
                height: 2px;
                width: 0px;
                background-color: $third-color;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                transition: width 0.3s;
                @media (max-width: 992px) {
                  display: none;
                }
              }
              &:hover {
                &:after {
                  width: 30px;
                }
              }
            }
          }
        }
      }
      h2 {
        text-align: center;
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;

        @media (max-width: 992px) {
          font-size: 50px;
          line-height: 50px;
        }
        @media (max-width: 576px) {
          font-size: 40px;
          line-height: 40px;
        }
        span {
          display: block;
          color: $primary-color;
          margin-top: 15px;
          transition: margin-top 0.5s;
          transition-delay: 0.4s;
          &:last-child {
            font-weight: 500;
            transition-delay: 0.5s;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &.ready {
    .background-image {
      > div {
        opacity: 1;
        transform: translate(-50%, -50%);
        svg {
          margin-top: 0;
        }
        h2 {
          span {
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-top: 0;
            }
          }
        }
        nav {
          margin-top: 0;
        }
      }
    }
  }
}
