@use "variables" as *;

.menu {
  &.reduced {
    margin-top: -110px;
    opacity: 0;
  }

  background-color: $background-color;
  width: 100%;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  padding: 15px 0;

  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0px;
  opacity: 1;
  transition: margin-top 0.5s, opacity 0.3s;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .burger-menu {
      position: absolute;
      top: 0;
      width: 35px;
      height: 105px;
      display: none;
      left: -50%;
      transition: left 0.5s;
      @media (max-width: 992px) {
        display: flex;
        align-items: center;
      }
      @media (max-width: 576px) {
        left: -50px;
      }
      > div {
        cursor: pointer;
        position: absolute;
        width: 30px;
        height: 25px;

        .line {
          position: absolute;
          height: 3px;
          background-color: $primary-color;
          width: 30px;
          display: block;
          transition: background-color 0.3s, transform 0.3s, top 0.3s, left 0.3s,
            margin-left 0.3s;
          border-radius: 5px;
          &.middle {
            top: 50%;
            transform: translateY(-50%);
          }
          &.first {
            top: 0;
          }
          &.last {
            bottom: 0;
          }
        }
      }

      &.open {
        left: 42px;
        > div {
          .middle {
            background-color: transparent;
            transform: translateX(100%);
          }
          .first {
            top: 50%;
            transform-origin: center;
            transform: rotate(-135deg);
          }
          .last {
            top: 50%;
            transform-origin: center;
            transform: rotate(135deg);
          }
        }
      }
    }
  }
  .logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
      height: 75px;
      pointer-events: none;
    }
    h1 {
      font-size: 1.5rem;
      line-height: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      margin-left: 15px;
      pointer-events: none;
      span {
        &:nth-child(1) {
          font-weight: 600;
          color: $primary-color;
          pointer-events: none;
        }
        &:last-child {
          color: $third-color;
        }
      }
    }
  }
  nav {
    transition: margin-right 0.5s;
    display: flex;
    align-items: center;
    ul {
      list-style-type: none;
      display: flex;
      margin: 0;
      li {
        a {
          display: block;
          text-decoration: none;
          color: $black;
          padding: 5px 10px;
          position: relative;
          &:after {
            content: "";
            height: 2px;
            width: 0px;
            background-color: $third-color;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: width 0.3s;
            @media (max-width: 992px) {
              display: none;
            }
          }
          &:hover {
            &:after {
              width: 30px;
            }
          }
          svg {
            display: none;
            pointer-events: none;
          }
          span {
            pointer-events: none;
          }
        }
      }
    }
    @media (max-width: 992px) {
      position: absolute;
      margin-right: -200px;
      width: 200px;
      top: 0;
      right: 0;
      padding: 105px 0;
      height: 100vh;
      background-color: $background-color;
      box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
      align-items: flex-start;
      ul {
        flex-direction: column;
        width: 100%;
        padding: 0;
        li {
          width: 100%;

          &:last-child {
            border: none;
          }
          a {
            padding: 15px 42px;
            font-size: 16px;
            display: flex;
            align-items: center;
            position: relative;
            &:after {
              content: "";
              height: 1px;
              width: 200px;
              position: absolute;
              bottom: 0;
              left: 42px;
              background-color: $third-color;
            }
            svg {
              display: inline;
              margin-right: 15px;
            }
          }
        }
      }
      &.open {
        display: flex;
        margin-right: 0px;
      }
    }
    // @media (max-width: 576px) {
    //   width: 100%;
    //   margin-right: -100%;
    //   height: 100vh;
    //   align-items: flex-start;
    //   &.open {
    //     display: flex;
    //     margin-right: 0;
    //   }
    // }
  }
}
