@use "variables" as *;

footer {
  background-color: $secondary-color;
  padding: 15px 0;
  p {
    margin: 0;
    color: $black;
    text-align: center;
    font-size: 12px;

    a {
      text-decoration: none;
      color: $black;
      transition: color 0.2s;
      &:hover {
        color: $clic-color;
      }
    }
  }
}
