// colors

$background-color: #f4f6f4;

$primary-color: #005c6f;
$third-color: #81c290;
$secondary-color: #e1e6e1;

$white: white;

$black: #212121;

$clic-color: #12b4bf;
