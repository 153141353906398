@use "variables" as *;

.contact {
  padding-bottom: 50px;
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    .map-container {
      padding-top: 50px;
      a {
        svg {
          fill: $clic-color;
          transition: fill 0.3s;
          &:hover {
            fill: $primary-color;
          }
        }
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
  .address-container {
    position: relative;
    background-color: $background-color;
    padding: 50px 100px;
    margin-top: -50px;
    border-radius: 5px;
    box-shadow: 0 0px 10px 5px rgb(0 0 0 / 15%);
    @media (max-width: 576px) {
      padding: 30px;
    }
    h3 {
      display: flex;
      flex-direction: column;
      span {
        &:first-child {
          padding-bottom: 15px;
          color: $third-color;
        }
      }
    }
  }
  iframe {
    width: 100%;
    margin-top: 50px;
    height: 300px;
  }
}
