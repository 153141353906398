@use "styles/variables" as *;
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;400;600&family=Cuprum:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: $black;
}
body {
  margin: 0;
  background-color: $background-color !important;
}

h1 {
  text-align: center;
  padding: 15px;
}
a{
  text-decoration: none;
  color: $clic-color;
  transition: color 0.5s;
  &:hover{
    color: $primary-color;
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
    position: relative;
    display: inline-block;
    margin: auto;
    padding-top: 30px;
    font-weight: 600;
    &:before {
      content: "";
      background-color: $third-color;
      position: absolute;
      height: 2px;
      width: 80px;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
button {
  background-color: $clic-color;
  
  border: none;
  color: $background-color;
  border-radius: 5px !important;
  a{
    color: $background-color;
    text-decoration: none;
    padding: 7px 20px;
    display: flex;
    transition: color 0.5s;
    &:hover{
      color: $primary-color;
    }
  }
}

@import "styles/home";
@import "styles/menu";
@import "styles/infos";
@import "styles/team";
@import "styles/contact";
@import "styles/footer";
@import "styles/loader";
@import "styles/blog";
