@use "variables" as *;

.infos {
  .bloc-info-2 {
    width: 100%;
    padding-bottom: 70px;
    padding-top: 50px;
    @media (max-width: 576px) {
      padding-bottom: 50px;
    }
    .img-container {
      display: flex;
      width: 100%;
      padding: 0 100px;
      @media (max-width: 992px) {
        padding: 0 50px;
      }
      @media (max-width: 576px) {
        flex-direction: column;
        padding: 0 15px;
      }
      > div {
        width: 33.33%;
        padding: 0 10px;
        @media (max-width: 992px) {
          width: 50%;
          &:nth-child(2) {
            display: none;
          }
        }
        @media (max-width: 576px) {
          width: 100%;
          padding: 10px 0;
          &:last-child {
            display: none;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .txt-container {
      padding: 30px;
      position: relative;
      &::before {
        background-color: $secondary-color;
        content: "";
        bottom: 0;
        height: auto;
        left: 0;
        width: 100%;
        height: calc(100% + 200px);
        z-index: -1;
        position: absolute;
        @media (max-width: 992px) {
          height: calc(100% + 150px);
        }
      }
      h3 {
        color: $black;
        text-align: center;
      }
      p {
        color: $black;
        text-align: center;
        font-weight: 300;
        padding: 0 100px;
        max-width: 900px;
        margin: auto;
        @media (max-width: 992px) {
          padding: 0 50px;
        }
        @media (max-width: 576px) {
          padding: 0 20px;
        }
      }
    }
  }
  .bloc-info-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    @media (max-width: 576px) {
      padding-bottom: 50px;
    }
    .txt-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35%;
      @media (max-width: 992px) {
        width: 100%;
      }
      @media (max-width: 576px) {
      }
      h2 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
    .img-container {
      width: 50%;
      background-image: url("../../public/pictures/cabinet-17.jpeg");
      height: 650px;
      background-position: center;
      background-size: cover;
      position: relative;
      @media (max-width: 992px) {
        width: 80%;
        margin-left: 20%;
        height: 550px;
      }
      @media (max-width: 576px) {
        height: 350px;
      }
      img {
        width: 50%;
        height: auto;
        border: 10px solid $background-color;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateX(-50%) translateY(-50%);
        @media (max-width: 992px) {
          border-left: none;
        }
        @media (max-width: 576px) {
          border: 5px solid $background-color;
          border-left: none;
        }
      }
    }
  }
}
