@use "variables" as *;

.team {
  padding-bottom: 50px;
  .team-member {
    width: 80%;
    display: flex;
    margin: auto;
    padding: 50px;
    @media (max-width: 992px) {
      width: 100%;
      padding: 30px 0;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      &:nth-child(2) {
        padding-top: 50px;
      }
    }
    h4{
      margin-bottom: 30px;
      font-size: 22px;
    }
    .txt-container {
      width: 60%;
      padding: 40px;
      position: relative;
      margin-top: 30px;
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        width: 100%;
        margin-top: 0;
      }
      &:before {
        content: "";
        background-color: $secondary-color;
        height: calc(100% + 40px);
        width: calc(100% + 50px);
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        @media (max-width: 576px) {
          width: 100%;
          height: calc(100% + 100px);
          top: auto;
          bottom: 0;
        }
      }
    }
    .img-container {
      width: 40%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 576px) {
        padding: 0 30px;
        width: 100%;
        max-height: 400px;
      }
      img {
        width: 100%;
        height: auto;
      }
      figcaption{
        font-size: 10px;
      }
    }
    
    &.odd {
      .txt-container {
        order: 1;
        &:before {
          right: auto;
          left: 0;
        }
      }
      .img-container {
        order: 2;
      }
      @media (max-width: 576px) {
        .txt-container {
          order: 2;
          &:before {
            right: 0;
            left: auto;
          }
        }
        .img-container {
          order: 1;
        }
      }
    }
  }
}
