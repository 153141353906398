@use "variables" as *;


.blog{
    background-color: $secondary-color;
    padding-bottom: 70px;
    margin-bottom: 30px;
    

    .title-container{
        margin-bottom: 50px;
    }

    .article{
        background-color: $white;
        padding: 30px 50px;
        @media (max-width: 576px) {
            padding: 30px;
          }
        .date-article{
            color: lightgray;
            text-align: right;
        }
        .author{
            padding-top: 15px;
            text-align: right;
        }
        .pictures{
            display: flex;
            justify-content: space-between;
            .img{
                width: 50%;
                display: flex;
                align-items: center;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            
            @media (max-width: 900px) {
                flex-direction: column;
                .img{
                    width: 100%;
                }
              }
        }
        h4{
            font-size: 1.4rem;
            padding: 10px 0;
        }
    }
    .slick-arrow{
        @media (max-width: 576px) {
            display: none !important;
          }
    }
    .slick-dots{
        li button:before{
            color: $white;
        }
        li.slick-active button:before{
            color: $white;
        }
    }
}